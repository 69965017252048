import { configureStore } from '@reduxjs/toolkit';
import appStateReducer from './app-slice';
import checklistAnswersReducer from './checklist-answers-slice';
import userReducer from './user-slice';
import analyticsReducer from './analytics-slice';
import checkListTeamsReducer from './checklist-team-slice';
import { baseApiSlice } from './api/rtk-api';

export const store = configureStore({
  reducer: {
    [baseApiSlice.reducerPath]: baseApiSlice.reducer,
    user: userReducer,
    appState: appStateReducer,
    checklistsAnswers: checklistAnswersReducer,
    checkListTeams: checkListTeamsReducer,
    analytics: analyticsReducer,
  },
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), baseApiSlice.middleware],
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
