'use client';

import React from 'react';
import { redirect, usePathname } from 'next/navigation';

import { AppRoutes, Keys, routesWithNoToken } from '@/constants/general';

type PrivacyProviderProps = {
  children: React.ReactNode;
};

const PrivacyProvider: React.FC<PrivacyProviderProps> = ({ children }) => {
  const pathname = usePathname();
  const mainRoute = pathname.split('/')[1];

  // if the user doesn't have a token and he's on a route that needs it, redirect to sign-in page
  if (
    !routesWithNoToken.has(mainRoute) &&
    typeof window !== 'undefined' &&
    !localStorage.getItem(Keys.TOKEN_STORAGE_KEY) &&
    !window.sessionStorage.getItem(Keys.TOKEN_STORAGE_KEY)
  ) {
    redirect(AppRoutes.SignIn);
  }

  return <>{children}</>;
};

export default PrivacyProvider;
