import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientAccount } from '../interfaces';
import { ChartsData } from '../interfaces/analytics/charts-data';

interface AnalyticsState {
  selectedAccount: Partial<ClientAccount>;
  chartsData: ChartsData;
  isDataLoading: boolean;
}

const initialState: AnalyticsState = {
  selectedAccount: {} as Partial<ClientAccount>,
  chartsData: {} as ChartsData,
  isDataLoading: false,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setChartsData: (state, action: PayloadAction<ChartsData>) => {
      state.chartsData = action.payload;
    },
    setIsDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isDataLoading = action.payload;
    },
  },
});

const { actions, reducer } = analyticsSlice;

export const { setChartsData, setIsDataLoading } = actions;

export default reducer;
