import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChecklistAnswers } from '../interfaces/checklist/checklist-answers';

interface ChecklistAnswersState {
  answers: ChecklistAnswers[];
  recentAnswers?: ChecklistAnswers;
  teamFilter: string;
  dateFilter: string;
  initiated: boolean;
}

const initialState: ChecklistAnswersState = {
  answers: [],
  teamFilter: '',
  dateFilter: '',
  initiated: false,
};

const checklistAnswersSlice = createSlice({
  name: 'checklistAnswers',
  initialState,
  reducers: {
    setRecentChecklistAnswers: (state, action: PayloadAction<string>) => {
      const selectedTeamId = action.payload;

      const payloadCopy = state.answers.filter((checklist) => checklist.isFinished && checklist.teamId === selectedTeamId);

      const sortedResults = payloadCopy.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
      });
      state.recentAnswers = sortedResults[0];
    },
    setCurrentTeamAnswers: (state, action) => {
      state.answers = action.payload;
    },
    addNewAnswers: (state, action: PayloadAction<ChecklistAnswers>) => {
      const unfinished = state.answers.find((answer) => !answer.isFinished);
      if (unfinished) {
        state.answers = state.answers.filter((answer) => answer.isFinished);
      }
      state.answers.push(action.payload);
      if (!action.payload.isFinished) return;
      state.recentAnswers = action.payload;
    },
    dropUnfinishedChecklist: (state) => {
      state.answers = state.answers.filter((answer) => answer.isFinished);
    },
    setTeamFilter: (state, action: PayloadAction<string>) => {
      state.teamFilter = action.payload;
    },
    setDateFilter: (state, action: PayloadAction<string>) => {
      state.dateFilter = action.payload;
    },
  },
});

const { actions, reducer } = checklistAnswersSlice;

export const {
  setRecentChecklistAnswers,
  setCurrentTeamAnswers,
  addNewAnswers,
  dropUnfinishedChecklist,
  setTeamFilter,
  setDateFilter,
} = actions;

export default reducer;
