import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChecklistTeamsState {
  selectedTeamId: string;
}

const initialState: ChecklistTeamsState = {
  selectedTeamId: '',
};

const checklistTeamsSlice = createSlice({
  name: 'checklistTeams',
  initialState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<string>) => {
      state.selectedTeamId = action.payload;
    },
  },
});

const { actions, reducer } = checklistTeamsSlice;

export const { setSelectedTeam } = actions;

export default reducer;
