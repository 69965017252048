import { useEffect } from 'react';
import { usePathname } from 'next/navigation';

const useScrollIntoView = () => {
  const pathname = usePathname();

  useEffect(() => {
    // wait for the dom to be fully rendered
    const timeoutId = setTimeout(() => {
      const highlightedTextElement = document.querySelector('.HighlightedText');

      if (highlightedTextElement) {
        highlightedTextElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        window.scrollTo(0, 0);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [pathname]);
};

export default useScrollIntoView;
