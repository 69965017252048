import { createTheme, Theme } from '@mui/material/styles';
import styles from './theme.module.scss';

export const theme: Theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 96,
      '@media (max-width:1799px)': {
        minHeight: 72,
      },
      '@media (max-width:1199px)': {
        minHeight: 48,
      },
    },
  },
  palette: {
    primary: {
      main: styles.primaryRed,
      contrastText: '#fff',
    },
    secondary: {
      main: '#F0F0F0',
      contrastText: '#fff',
    },
    text: {
      primary: '#000000',
    },
    error: {
      main: '#EA4335',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          height: '40px',
          fontSize: '18px',
          fontWeight: 600,
          boxShadow: 'none',
          borderRadius: '4px',
          color: '#141414',
        },
        containedPrimary: {
          '&:hover': {
            background: styles.primaryRedDarker,
            boxShadow: 'none',
          },
          '&:active': {
            background: styles.primaryRedDarkest,
            boxShadow: 'none',
          },
          '&:disabled': {
            background: '#F0F0F0',
          },
        },
        outlined: {
          fontSize: 12,
          fontWeight: 600,
        },
        outlinedPrimary: {
          '&:hover, &:active': {
            background: styles.primaryRedDarker,
            color: styles.primaryWhite,
            fontWeight: 'bold',
          },
          borderColor: styles.primaryRedDarker,
          borderRadius: 4,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.EmptyInput label:not(.Mui-error)': {
            color: '#7A7A7A',
            fontStyle: 'normal',
            fontWeight: 'normal',
          },
          '&.Account': {
            width: '100%',
            '& label': {
              fontSize: '18px',
              lineHeight: '28px',
              transform: 'translate(14px, 14px) scale(1)',

              '@media (max-width:1439px)': {
                fontSize: '16px',
                lineHeight: '24px',
              },
            },
            '& div': {
              height: '52px;',
              fontSize: '18px',
              lineHeight: '28px',
              padding: '12px',

              '& input': {
                lineHeight: '28px',
                height: '28px',
              },
              '@media (max-width:1439px)': {
                fontSize: '16px',
                lineHeight: '24px',
                height: '48px',
                '& input': {
                  lineHeight: '24px',
                  height: '24px',
                },
              },
            },
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: '#141414',
          padding: '8px',
          height: '40px',
          backgroundColor: '#F0F0F0',
          borderRadius: '4px',
          fontSize: '16px',
          lineHeight: '24px',
          '@media (max-width:1439px)': {
            fontSize: '14px',
            lineHeight: '20px',
            height: '36px',
            '& input': {
              lineHeight: '20px',
              height: '20px',
            },
          },
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
          '&$focused': {
            backgroundColor: styles.primaryWhite,
            border: '1px solid #006EF4',
          },
          '&$error': {
            backgroundColor: styles.primaryWhite,
            border: '1px solid #DC2020',
            '& input:-webkit-autofill': {
              boxShadow: `0 0 0 30px ${styles.primaryWhite} inset !important`,
            },
          },
          '& input:-webkit-autofill': {
            boxShadow: '0 0 0 30px #F0F0F0 inset !important',
            transition: 'box-shadow 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
          },
          '&:hover input:-webkit-autofill': {
            boxShadow: '0 0 0 30px #E0E0E0 inset !important',
          },
          '&$focused input:-webkit-autofill': {
            boxShadow: `0 0 0 30px ${styles.primaryWhite} inset !important`,
          },

          '&.PasswordSection': {
            height: '52px;',
            fontSize: '18px',
            lineHeight: '28px',
            padding: '12px',

            '@media (max-width:1439px)': {
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
        },
        input: {
          padding: '0px',
          lineHeight: '24px',
          height: '24px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          transform: 'translate(11px, 11px) scale(1)',
          lineHeight: '24px',
          fontStyle: 'normal',
          fontWeight: 400,
          color: '#90999F',
          fontSize: '16px',

          '&.PasswordSection': {
            transform: 'translate(15px, 15px) scale(1)',
          },

          '@media (max-width:1439px)': {
            fontSize: '14px',
            lineHeight: '20px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: '#4C5960',
          },
          '&$filled': {
            display: 'none',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          color: '#DC2020',
          fontSize: '12px',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '6px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: '8px',
          padding: '0',
          color: styles.primaryWhite,

          '&:hover span.MuiSlider-thumb': {
            width: '16px',
            height: '16px',
            marginTop: '-4px',
          },

          '&.MuiSlider-vertical': {
            padding: '0',
            color: styles.primaryWhite,

            '& .MuiSlider-rail': {
              width: '8px',
            },

            '& .MuiSlider-track': {
              width: '8px',
              borderRadius: '2px 2px 5px 5px',
            },

            '& .MuiSlider-thumb': {
              marginLeft: '0px',
            },

            '&:hover span.MuiSlider-thumb': {
              marginLeft: '-4px',
            },
          },
        },

        rail: {
          height: '8px',
          borderRadius: '5px',
          backgroundColor: 'rgba(244, 244, 244, 0.4)',
          opacity: 1,
        },
        track: {
          height: '8px',
          borderRadius: '5px 2px 2px 5px;',
        },
        thumb: {
          width: '8px',
          height: '8px',
          marginTop: '0px',

          '&:focus, &:hover, &$active': {
            boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 30%)',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Brown',
  },
});
